import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { FederacaoLayoutComponent } from 'src/app/containers/federacao-layout';

// Guards
import { AuthGuard, PermissionGuard, UnidadeGuard } from 'src/app/core/guards';

// Pages
import {
  P404Component, P500Component
} from 'src/app/core/views';

import { baseRoutes } from 'src/app/apps/shared/base-routes';

export const routes: Routes = [
  ...baseRoutes,
  {
    path: '',
    component: FederacaoLayoutComponent,
    data: { title: 'Home' },
    canActivate: [AuthGuard, UnidadeGuard],
    canActivateChild: [PermissionGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      }, {
        path: 'configuracao',
        loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule)
      }, {
        path: 'cadastro',
        loadChildren: () => import('./modules/cadastro/cadastro.module').then(m => m.CadastroModule)
      }, {
        path: 'ecm',
        loadChildren: () => import('./modules/ecm/ecm.module').then(m => m.EcmModule)
      }, {
        path: 'finance',
        loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule)
      },
      {
        path: 'patrimonio',
        loadChildren: () => import('./modules/patrimonio/patrimonio.module').then(m => m.PatrimonioModule)
      }, {
        path: 'juridico',
        loadChildren: () => import('./modules/juridico/juridico.module').then(m => m.JuridicoModule)
      },
      {
        path: 'schedule',
        loadChildren: () => import('./modules/schedule/schedule.module').then(m => m.ScheduleModule)
      },
      {
        path: '404',
        component: P404Component,
        data: { title: 'Não Encontrato' }
      },
      {
        path: '500',
        component: P500Component,
        data: { title: 'Erro' }
      },
      { path: '**', component: P404Component }
    ]
  },
  {
    path: '404', pathMatch: 'full', data: { title: 'Não Encontrato' },
    component: P404Component,
  },
  {
    path: '500', pathMatch: 'full', data: { title: 'Erro' },
    component: P500Component,
  },
  // { path: '**', redirectTo: '' },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class FederacaoRoutingModule { }
