import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  // ---- ATENDIMENTO ----
  {
    name: 'Atendimento',
    icon: 'far fa-calendar',
    url: '/schedule',
    attributes: { permission: { namespace: 'schedule' } },
    children: [
      {
        name: 'Agendas',
        icon: 'far fa-calendar',
        url: '/schedule/schedule',
        attributes: { permission: { namespace: 'schedule', action: 'schedule_list' } },
      },
      {
        name: 'Atender',
        icon: 'fas fa-user-clock',
        url: '/schedule/attend',
        attributes: { permission: { namespace: 'schedule', action: 'attendance_create' } },
      },
      {
        name: 'Atendimentos',
        icon: 'fas fa-user-check',
        url: '/schedule/attendance',
        attributes: { permission: { namespace: 'schedule', action: 'attendance_list' } },
      },
      {
        name: 'Diversos',
        icon: 'fas fa-bars',
        url: '.',
        children: [
          {
            name: 'Categorias',
            url: '/schedule/procedure-category',
            icon: 'fas fa-layer-group',
            attributes: { permission: { namespace: 'schedule', action: 'procedure_category_list' } },
          },
          {
            name: 'Convênios',
            url: '/schedule/covenant',
            icon: 'fas fa-hands-helping',
            attributes: { permission: { namespace: 'schedule', action: 'covenant_list' } },
          },
          {
            name: 'Modelo Atendimento',
            url: '/schedule/attendance-template',
            icon: 'fab fa-buromobelexperte',
            attributes: { permission: { namespace: 'schedule', action: 'attendance_template_list' } },
          },
          {
            name: 'Procedimentos',
            url: '/schedule/procedure',
            icon: 'fa fa-user-md',
            attributes: { permission: { namespace: 'schedule', action: 'procedure_list' } },
          },
        ]
      },
      // {
      //   name: 'Relatórios',
      //   url: '/schedule/report',
      //   icon: 'fas fa-print',
      //   attributes: { permission: { namespace: 'schedule' } },
      // },
    ]
  },
  // ---- Convênio ----
  {
    name: 'Convênio',
    icon: 'fas fa-share-alt',
    url: '/covenant',
    attributes: { permission: { namespace: 'covenant' } },
    children: [
      {
        name: 'Procedimentos',
        url: '/covenant/procedure',
        icon: 'fa fa-user-md',
        attributes: { permission: { namespace: 'covenant', action: 'procedure_list' } },
      },
      {
        name: 'Requisição',
        url: '/covenant/request',
        icon: 'far fa-file-alt',
        attributes: { permission: { namespace: 'covenant', action: 'request_list' } },
      },
      {
        name: 'Relatórios',
        url: '/covenant/report',
        icon: 'fas fa-print',
        attributes: { permission: { namespace: 'covenant' } },
      },
    ]
  },

  // ---- Documentos ----
  {
    name: 'Documentos',
    icon: 'far fa-file-alt',
    url: '/ecm',
    attributes: { permission: { namespace: 'ecm' } },
    children: [
      {
        name: 'Dashboard',
        url: '/ecm/dashboard',
        icon: 'icon-speedometer',
        attributes: { permission: { namespace: 'ecm' } },
      },
      {
        name: 'Documentos',
        url: '/ecm/document',
        icon: 'far fa-file-alt',
        attributes: { permission: { namespace: 'ecm', action: 'file_list' } },
      },
      {
        name: 'Monitoramento',
        url: '/ecm/watch',
        icon: 'fas fa-binoculars',
        attributes: { permission: { namespace: 'ecm', action: 'watch_log_list' } },
      },
      {
        name: 'Diversos',
        icon: 'fas fa-ellipsis-h',
        url: '.',
        children: [
          // {
          //   name: 'Monitoramento',
          //   url: '/ecm/watch',
          //   icon: 'fas fa-tasks',
          //   attributes: { permission: { namespace: 'ecm', action: 'watch_log_list' } },
          // },
          {
            name: 'Índices',
            url: '/ecm/template',
            icon: 'far fa-copy',
            attributes: { permission: { namespace: 'ecm', action: 'template_list' } },
          },
          {
            name: 'Upload',
            url: '/ecm/upload',
            icon: 'fas fa-cloud-upload-alt',
            attributes: { permission: { namespace: 'ecm', action: 'file_create' } },
          },
        ]
      },
      {
        name: 'Relatório',
        url: '/ecm/report',
        icon: 'fas fa-print',
        attributes: { permission: { namespace: 'ecm', action: 'file_view' } },
      },
    ]
  },

  // ---- Federação ----
  // {
  //   name: 'Federação',
  //   icon: 'fa fa-sitemap',
  //   url: '/federacao',
  //   attributes: { permission: { namespace: 'federacao' } },
  //   children: [
  //     {
  //       name: 'Federados',
  //       url: '/federacao/federados',
  //       icon: 'fa fa-building',
  //       attributes: { permission: { namespace: 'federacao', action: 'federados_list' } },
  //     },
  //   ]
  // },

  // ---- Financeiro ----
  {
    name: 'Financeiro',
    icon: 'fas fa-wallet',
    url: '/finance',
    attributes: { permission: { namespace: 'finance' } },
    children: [
      {
        name: 'Banco / Caixa',
        url: '/finance/lancamento',
        icon: 'fas fa-wallet',
        attributes: {
          permission: { namespace: 'finance', action: 'lancamento_list' },
          headerNav: true
        },
      },
      {
        name: 'Contas',
        icon: 'fas fa-file-invoice-dollar',
        url: '.',
        children: [
          {
            name: 'Pagar',
            url: '/finance/conta-pagar',
            icon: 'fas fa-money-bill-wave',
            attributes: { permission: { namespace: 'finance', action: 'conta_pagar_list' } },
          },
          {
            name: 'Receber',
            url: '/finance/conta-receber',
            icon: 'fas fa-hand-holding-usd',
            attributes: {
              permission: { namespace: 'finance', action: 'conta_receber_list' },
              headerNav: true, headerNavName: 'Conta Receber'
            },
          },
        ]
      },
      {
        name: 'Cheques',
        icon: 'fas fa-money-check-alt',
        url: '/finance/cheque/',
        attributes: { permission: { namespace: 'finance', action: 'cheque' } },
      },
      {
        name: 'RDA',
        icon: 'fas fa-money-check-alt',
        url: '/finance/rda/',
        attributes: { permission: { namespace: 'federacao', action: 'rda_list' } },
      },
      {
        name: "Troca Arquivos",
        icon: "fas fa-file-export",
        url: ".",
        children: [
          {
            name: "Boletos",
            icon: "fas fa-barcode",
            url: ".",
            children: [
              {
                name: "Remessa",
                url: "/finance/boleto/remessa",
                icon: "fa fa-upload",
                attributes: {
                  permission: { namespace: "finance", action: "remessa_list" },
                },
              },
              {
                name: "Retorno",
                url: "/finance/boleto/retorno",
                icon: "fa fa-download",
                attributes: {
                  permission: { namespace: "finance", action: "retorno_list" },
                },
              },
            ],
          },
          {
            name: "Deb. Conta",
            icon: "fas fa-file-invoice-dollar",
            url: ".",
            children: [
              {
                name: "Remessa",
                url: "/finance/debito-conta/remessa",
                icon: "fa fa-upload",
                attributes: {
                  permission: { namespace: "finance", action: "debito_conta_list" },
                },
              },
              {
                name: "Retorno",
                url: "/finance/debito-conta/retorno",
                icon: "fa fa-download",
                attributes: {
                  permission: { namespace: "finance", action: "debito_conta_list" },
                },
              },
            ],
          },
        ],
      },
      {
        name: 'Diversos',
        icon: 'fas fa-ellipsis-h',
        url: '.',
        children: [
          {
            name: 'Banco / Caixa',
            icon: 'fas fa-wallet',
            url: '.',
            children: [
              {
                name: 'Conta',
                url: '/finance/conta',
                attributes: { permission: { namespace: 'finance', action: 'conta_list' } },
              },
              {
                name: 'Carteira Cobrança',
                url: '/finance/carteira-cobranca',
                attributes: { permission: { namespace: 'finance', action: 'carteira_cobranca_list' } },
              },
              {
                name: 'Tipo Conta',
                url: '/finance/tipo-conta',
                attributes: { permission: { namespace: 'finance', action: 'conta_tipo_list' } },
              },
            ]
          },
          {
            name: 'Cartões',
            icon: 'fas fa-credit-card',
            url: '.',
            children: [
              {
                name: 'Meus Cartões',
                url: '/finance/cartao',
                icon: 'far fa-address-card',
                attributes: { permission: { namespace: 'finance', action: 'cartao_list' } },
              },
              {
                name: 'Bandeiras',
                url: '/finance/cartao-bandeira',
                icon: 'fab fa-cc-visa',
                attributes: { permission: { namespace: 'finance', action: 'cartao_bandeira_list' } },
              },
              {
                name: 'Contratos',
                url: '/finance/cartao-contrato',
                icon: 'fas fa-file-signature',
                attributes: { permission: { namespace: 'finance', action: 'cartao_contrato_list' } },
              },
            ]
          },
          {
            name: 'Categorias',
            icon: 'fas fa-layer-group',
            url: '.',
            children: [
              {
                name: 'Entradas',
                url: '/finance/categoria-entrada',
                icon: 'fas fa-arrow-down',
                attributes: { permission: { namespace: 'finance', action: 'categoria_entrada_list' } },
              },
              {
                name: 'Saídas',
                url: '/finance/categoria-saida',
                icon: 'fas fa-arrow-up',
                attributes: { permission: { namespace: 'finance', action: 'categoria_saida_list' } },
              },
              {
                name: 'Padrão',
                url: '/finance/categoria-padrao',
                icon: 'fas fa-sliders-h',
                attributes: { permission: { namespace: 'finance', action: 'categoria_padrao_view' } },
              },
            ]
          },
          {
            name: 'Formas',
            icon: 'far fa-handshake',
            url: '.',
            children: [
              {
                name: 'Pagamento',
                url: '/finance/forma-pagamento',
                icon: 'fas fa-arrow-up',
                attributes: { permission: { namespace: 'finance', action: 'forma_pagamento_list' } },
              },
              {
                name: 'Recebimento',
                url: '/finance/forma-recebimento',
                icon: 'fas fa-arrow-down',
                attributes: { permission: { namespace: 'finance', action: 'forma_recebimento_list' } },
              },
            ]
          },
        ]
      },
      {
        name: 'Relatórios',
        url: '/finance/report',
        icon: 'fas fa-print',
        attributes: { permission: { namespace: 'finance' } },
      },
    ]
  },

  // ---- JURÍDICO ----
  {
    name: 'Jurídico',
    icon: 'fas fa-gavel',
    url: '/juridico',
    attributes: { permission: { namespace: 'juridico' } },
    children: [
      {
        name: 'Processo',
        url: '/juridico/processo',
        icon: 'fas fa-gavel',
        attributes: { permission: { namespace: 'juridico', action: 'processo_list' } },
      },
      {
        name: 'Diversos',
        icon: 'fas fa-ellipsis-h',
        url: '.',
        children: [
          {
            name: 'Ações',
            url: '/juridico/acao',
            icon: 'far fa-bookmark',
            attributes: { permission: { namespace: 'juridico', action: 'acao_list' } },
          },
          {
            name: 'Foro',
            url: '/juridico/foro',
            icon: 'fa fa-university',
            attributes: { permission: { namespace: 'juridico', action: 'foro_list' } },
          },
          {
            name: 'Tipo de Processo',
            url: '/juridico/tipo-processo',
            icon: 'fa fa-hashtag',
            attributes: { permission: { namespace: 'juridico', action: 'tipo_processo_list' } },
          }
        ]
      },
      {
        name: 'Relatórios',
        url: '/juridico/relatorio',
        icon: 'fas fa-print',
        attributes: { permission: { namespace: 'juridico', action: 'processo_list' } },
      },
    ]
  },

  // ---- PATRIMÔNIO ----
  {
    name: 'Patrimônio',
    icon: 'fas fa-tv',
    url: '/patrimonio',
    attributes: { permission: { namespace: 'patrimonio' } },
    children: [
      {
        name: 'Bem',
        url: '/patrimonio/bem',
        icon: 'fas fa-tv',
        attributes: { permission: { namespace: 'patrimonio', action: 'bem_list' } },
      },
      {
        name: 'Diversos',
        icon: 'fas fa-ellipsis-h',
        url: '.',
        children: [
          {
            name: 'Tipo de Bem',
            url: '/patrimonio/tipo-bem',
            icon: 'fas fa-vector-square',
            attributes: { permission: { namespace: 'patrimonio', action: 'tipo_bem_list' } },
          },
          {
            name: 'Grupo',
            url: '/patrimonio/grupo',
            icon: 'far fa-object-group',
            attributes: { permission: { namespace: 'patrimonio', action: 'grupo_list' } },
          },
          {
            name: 'Estado de Conservação',
            url: '/patrimonio/estado-conservacao',
            icon: 'fas fa-hourglass-half',
            attributes: { permission: { namespace: 'patrimonio', action: 'estado_conservacao_list' } },
          },
          {
            name: 'Tipo de Aquisição',
            url: '/patrimonio/tipo-aquisicao',
            icon: 'fas fa-funnel-dollar',
            attributes: { permission: { namespace: 'patrimonio', action: 'tipo_aquisicao_list' } },
          },
          {
            name: 'Tipo de Movimentação',
            url: '/patrimonio/tipo-movimentacao',
            icon: 'fas fa-expand-arrows-alt',
            attributes: { permission: { namespace: 'patrimonio', action: 'tipo_movimentacao_list' } },
          },
        ]
      },
    ]
  },

  // ---- Pessoas ----
  {
    name: 'Pessoas',
    icon: 'fas fa-user-friends',
    url: '/cadastro',
    attributes: { permission: { namespace: 'people' } },
    children: [
      {
        name: 'Contatos',
        url: '/cadastro/contact',
        icon: 'fas fa-user',
        attributes: { permission: { namespace: 'people', action: 'contact_list' } },
      },
      {
        name: 'Pessoas',
        url: '/cadastro/person',
        icon: 'fas fa-user-friends',
        attributes: { permission: { namespace: 'people', action: 'person_list' } },
      },
      {
        name: 'Membros',
        url: '/cadastro/member',
        icon: 'fa fa-users',
        attributes: {
          permission: { namespace: 'associated', action: 'member_list' },
          headerNav: true
        },
      },
      {
        name: 'Sincronizar',
        url: '/cadastro/sincronizar',
        icon: 'fa fa-building',
        attributes: { permission: { namespace: 'federacao', action: 'federados_list' } },
      },
      {
        name: 'Diversos',
        icon: 'fas fa-ellipsis-h',
        url: '.',
        children: [
          {
            name: 'Diretores',
            url: '/cadastro/direction',
            icon: 'fas fa-user-tie',
            attributes: { permission: { namespace: 'associated', action: 'direction_list' } },
          },
          {
            name: 'Empregadores',
            url: '/cadastro/employer',
            icon: 'fas fa-user-tie',
            attributes: { permission: { namespace: 'people', action: 'employer_list' } },
          },
          {
            name: 'Fornecedores',
            url: '/cadastro/person-provider',
            icon: 'fas fa-dolly',
            attributes: { permission: { namespace: 'people', action: 'person_provider_list' } },
          },
          {
            name: 'Funcionários',
            url: '/cadastro/employee',
            icon: 'fas fa-user-cog',
            attributes: { permission: { namespace: 'people', action: 'employee_list' } },
          },
          {
            name: 'Profissionais',
            url: '/cadastro/professional',
            icon: 'fa fa-briefcase',
            attributes: { permission: { namespace: 'people', action: 'professional_list' } },
          },
          {
            name: 'Profissionais',
            url: '/cadastro/professional',
            icon: 'fa fa-briefcase',
            attributes: { permission: { namespace: 'people', action: 'profissional_list' } },
          },
          {
            name: 'Cargo',
            url: '/cadastro/job-role',
            icon: 'far fa-circle',
            attributes: { permission: { namespace: 'people', action: 'job_role_list' } },
          },
          {
            name: 'Categorias',
            url: '/cadastro/categorias',
            icon: 'far fa-circle',
            attributes: { permission: { namespace: 'people', action: 'category_list' } },
          },
          {
            name: 'Comunidade',
            url: '/cadastro/community',
            icon: 'far fa-circle',
            attributes: { permission: { namespace: 'people', action: 'comunity_list' } },
          },
          {
            name: 'Parentesco',
            url: '/cadastro/kinship',
            icon: 'far fa-circle',
            attributes: { permission: { namespace: 'people', action: 'kinship_list' } },
          },
          {
            name: 'Profissão',
            url: '/cadastro/occupation',
            icon: 'far fa-circle',
            attributes: { permission: { namespace: 'people', action: 'occupation_list' } },
          },
          {
            name: 'Setor',
            url: '/cadastro/sector',
            icon: 'far fa-circle',
            attributes: { permission: { namespace: 'people', action: 'sector_list' } },
          },
        ]
      },
      // {
      //   name: 'Relatórios',
      //   url: '/cadastro/report',
      //   icon: 'fas fa-print',
      //   attributes: { permission: { namespace: 'people' } },
      // },
    ]
  },
];
