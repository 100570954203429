import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { FederacaoModule } from '../app/apps/federacao/federacao.module';
import { environment } from '../environments/environment';

if (environment.production) {
    enableProdMode();
}

document.title = 'Federação';

window.addEventListener('dragover', event => event.preventDefault(), false);
window.addEventListener('drop', event => event.preventDefault(), false);

platformBrowserDynamic().bootstrapModule(FederacaoModule);
